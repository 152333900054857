<template>
  <section>
	<div class="main mt-3" id="">
		<div class="header-block">
			<h1>Recovering password</h1>
		</div>
    <div class="content-page" v-if="loading">
			<div class="preloader">
				<ProgressSpinner strokeWidth="8" animationDuration=".7s"/>
			</div>			
		</div>
		<div class="grid content-page">
    <div class="loginCon">
			<div class="loginBox">
				<div class="returningCustomer">
					<div class="retCustHd">
						<i class="returingCutIcon"></i>
						<strong>Forgot password?</strong>
						<span>Input your registered e-mail</span>
					</div>
					<div class="loginbxLeft">
            <InputText
              v-model.trim="email"
              class="inputBox required email w-full mt-1 mb-5"
              placeholder="Enter registered Email Address" />
            <Button label="Send e-mail" @click="recoverPassword" class="submitBtn mt-5 loginsubmit"/>
						<div class="rightLine"></div>
					</div>
				</div>	
				<div class="newCusromer">
					<div class="newCustHd">
						<i class="newCusticon"></i>
						<strong>Are you a New Customer?</strong>
						<span>Please click below to register</span>
					</div>
					<div class="loginbxRight">
            <Button label="Click Here To Register" @click="$router.push('/register')" class="btn register"/>
              <span>or</span>
            <Button label="Log In" @click="$router.push('/login')" class="btn Guest"/>
					</div>
				</div>
				<div class="clearfix"></div>
			</div>
		</div>
    </div>
	</div>
  <Toast />
</section>
</template>
<script>
export default {
  name: 'PasswordForgotPage',
  data() {
      return {
        email: null,
        loading: false
      }
    },
    methods: {
      async recoverPassword() {
        this.loading = true;
        try {
         let response = await this.$store.dispatch('forgotPassword', {
            email: this.email,
          });

          if (response && response.status && response.status.type == 'Success') {
            this.$toast.add({severity:'success', summary: 'Success Message', detail: `${response.status.message}`, life: 3000 });
            this.loading = false;
          }
          if (response && response.status && response.status.type == 'Error') {
            this.$toast.add({ severity: 'error', summary: 'Error Message', detail: `${response.status.message}`, life: 3000 });
          }
        } catch (error) {
        }
      }
    },
    async created() {
      if (this.$route.meta.noAuthRequired) {
        return;
      }
      let isValid = await this.$store.dispatch('checkLoginState');
      if (isValid) {
        this.$router.push({
          name: 'home'
        });
      }
    }
}
</script>
<style scoped>
  h1{
  margin-top: 20px;
  font-family: Oswald;
  font-size: 26px;
}
.loginHd {
  color: #272727;
  font-family: oswaldbook;
  font-size: 26px;
  text-align: center;
  padding: 30px 0;
}
.loginBox {
  background: #fff;
  width: 110%;
  max-width: 734px;
  padding: 20px;
  min-height: 200px;
  margin: auto;
  padding-bottom: 50px;
}
.returningCustomer {
  width: 50%;
  float: left;
  padding-right: 40px;
}
.retCustHd {
  font-family: open_sanssemibold;
  position: relative;
  padding-left: 36px;
}
.returingCutIcon {
  width: 28px;
  height: 32px;
  text-align: left;
  background: url('../../../public/images/template/returning_customer_icon.png') no-repeat center center;
  display: block;
  position: absolute;
  left: 0;
  top: 6px;
}
i {
  font-style: italic;
}
.loginBox strong {
  color: #000;
  text-transform: uppercase;
  font-size: 18px;
  font-family: open_sanssemibold;
  display: block;
}
b, strong {
  font-weight: 700;
}
strong {
  font-weight: bold;
}
.loginBox span {
  color: #64676b;
  text-transform: inherit;
  font-size: 14px;
  display: block;
  font-family: open_sanssemibold;
}
.loginbxLeft {
  margin-top: 20px;
  position: relative;
}
form {
  display: block;
  margin-top: 0em;
}
ol, ul {
  margin-top: 0;
  margin-bottom: 10px;
}
ul {
  padding: 0;
  margin: 0;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.loginbxLeft ul li {
  margin-top: 15px;
}
li {
  list-style: none;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
ul {
  list-style-type: disc;
}
.loginbxLeft ul li {
  margin-top: 15px;
}
.checkLi {
  margin-top: 8px!important;
}
button, input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
input {
  line-height: normal;
}
button, input, optgroup, select, textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}
input {
  outline: none;
  transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  outline: none;
}
input[type="hidden" i] {
  appearance: none;
  background-color: initial;
  cursor: default;
  display: none !important;
  padding: initial;
  border: initial;
}
input {
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: fieldtext;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
  background-color: field;
  margin: 0em;
  padding: 1px 2px;
  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
}
.inputBox {
  border: 1px solid #c3b8b8 !important;
  color: #9a9aba;
  display: block;
  font-family: open_sansregular;
  font-size: 13px;
  font-weight: normal;
  padding: 8px;
  width: 100%;
}
label.error {
  color: red;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
label {
  cursor: default;
}
input[type=checkbox], input[type=radio] {
  margin: 4px 0 0;
  margin-top: 1px\9;
  line-height: normal;
}
input[type=checkbox], input[type=radio] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
.checkbox, .radio {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.checkbox {
  float: left;
}
.checkLi > span {
  float: left;
  font-size: 12px;
  margin: 3px 3px;
  color: #ababab;
}
.checkLi a {
  color: #ed640d;
  font-size: 13px;
  float: right;
  font-family: open_sanssemibold;
  margin-top: -2px;
}
a {
  color: #337ab7;
  text-decoration: none;
}
a {
  background-color: transparent;
}
a {
  text-decoration: none;
  cursor: pointer;
  transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  outline: none;
}
a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}
.clearfix {
  clear: both;
}
button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}
.submitBtn {
  background: #ed640d;
  color: #fff;
  font-size: 14px;
  font-family: open_sanssemibold;
  text-transform: uppercase;
  display: block;
  cursor: pointer;
  box-shadow: 0 0 3px #ddd inset;
  width: 100%;
  padding: 6px 0;
  border-radius: 3px;
  border: 1px solid #dc6b00;
}
.rightLine {
  background: #dde2e5 none repeat scroll 0 0;
  height: 100%;
  position: absolute;
  right: -50px;
  top: 0;
  width: 1px;
}
.newCusromer {
  width: 50%;
  float: right;
  padding-left: 15px;
}
.newCustHd {
  position: relative;
  padding-left: 39px;
}
.newCusticon {
  width: 35px;
  height: 35px;
  text-align: left;
  background: url('../../../public/images/template/new_custome.png') no-repeat center center;
  display: block;
  position: absolute;
  left: 0;
  top: 4px;
}
.loginbxRight .btn {
  background: url('../../../public/images/template/loginBtnsback.png') repeat-x;
  width: 240px;
  border: 1px solid #737b82;
  border-radius: 3px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  display: block;
  margin: 20px auto;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
a {
  color: #337ab7;
  text-decoration: none;
}
a {
  background-color: transparent;
}
a {
  text-decoration: none;
  cursor: pointer;
  transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  outline: none;
}
.loginbxRight > span {
  border: 1px solid #c8c8c8;
  border-radius: 100%;
  display: block;
  font-size: 12px;
  height: 30px;
  line-height: 26px;
  margin: 22px auto;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  width: 30px;
}
.loginCon{
  margin: 0 auto !important;
}
.forgot{
  color: #ed640d !important;
}
.p-inputtext{
  padding: 8px !important;
}
</style>
  
