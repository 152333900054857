<template>
<div class="main mt-3" >
		<div class="header-block">
			<h1>My Profile</h1>
		</div>
    <div class="card">
      <div>
          <div class="flex">
              <left-sidebar />
              <div class="flex-auto flex text-gray-900">
                <div class="card-container">
                  <div class="block text-lg">Hello <strong class="text-orange-500">{{ this.$store.state.profileModule.userDisplayName }}</strong></div>
                  <div class="block">Click here to <span class="text-orange-500 cursor-pointer" @click="$router.push('/user/edit-profile')">edit your profile</span></div>
                  <div class="block">Click here to <span class="text-orange-500 cursor-pointer" @click="$router.push('/user/settings')">change password</span></div>

                  <div class="block mt-2 mb-1"><span class="font-medium">PERSONAL INFORMATION</span></div>
                  <div class="block">
                    <table class="table w-full border-0 table-borderless">
                      <tr>
                        <td><b>Name</b></td>
                        <td>{{ first_name }} {{ last_name }}</td>
                      </tr>
                      <tr>
                        <td><b>Email</b></td>
                        <td>{{ this.$store.state.profileModule.userEmail }}</td>
                      </tr>
                      <tr>
                        <td><b>Country</b></td>
                        <td>{{ country || '&mdash;' }}</td>
                      </tr>
                      <tr>
                        <td><b>Postal Code</b></td>
                        <td>{{ postal_code || '&mdash;'}}</td>
                      </tr>
                      <tr>
                        <td><b>State</b></td>
                        <td>{{ state || '&mdash;' }}</td>
                      </tr>
                      <tr>
                        <td><b>City</b></td>
                        <td>{{ city || '&mdash;' }}</td>
                      </tr>
                      <tr>
                        <td><b>Street Address</b></td>
                        <td>{{ address || '&mdash;' }}</td>
                      </tr>
                      <tr>
                        <td><b>Mobile</b></td>
                        <td>{{ phone || '&mdash;' }}</td>
                      </tr>
                    </table>
                  </div>
                 
                </div>
              </div>
          </div>
      </div>
  </div>
	</div>
</template>
<script>
import axios from 'axios';
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import LeftSidebar from '@/components/profile/LeftSidebar';
export default {
  name: 'ProfilePage',
  components: {
    LeftSidebar: LeftSidebar,
  },
  data() {
    return {
      first_name: null,
      last_name: null,
      country: null,
      state: null,
      city: null,
      postal_code: null,
      address: null,
      phone: null
    }
  },
  methods: {
    getCountries() {
          let form = new FormData;
          form.append('action', 'dm_get_countries');
          axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
          }).then((response) => {
            if (response.data.contries.length) {
              this.country = response.data.contries[0].name;
            }
          }).catch(error => {
            console.log(error);
          });
        },
        
     async getPersonalInfo() {
      let personalData = await this.$store.dispatch('getPersonalInfo', {
        'user_email': this.$store.state.profileModule.userEmail
      });
      if (personalData) {
        this.first_name = personalData.status.data.first_name;
        this.last_name = personalData.status.data.last_name;
        this.contry = personalData.status.data.country;
        this.state = personalData.status.data.state;
        this.city = personalData.status.data.city;
        this.postal_code = personalData.status.data.postal_code;
        this.address = personalData.status.data.address;
        this.phone = personalData.status.data.phone;
      }
    }
  },
  async created () {
    if (this.$route.meta.noAuthRequired) {
      return;
    }
    let isValid = await this.$store.dispatch('checkLoginState');
    if (!isValid) {
      this.$router.push({
        name: 'login'
      });
    }
  },
  beforeRouteUpdate (to, from, next) {
  },
  mounted() {
    this.getCountries();
    this.getPersonalInfo();
  }
}
</script>    
<style scoped>
h1{
  margin-left: 10px;
  font-size: 27px;
  font-family: Oswald;
}    
.card {
  background: #ffffff;
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  border-radius: 4px;
  margin-bottom: 2rem;
  display: flex;
}
.loginHd {
  color: #272727;
  font-family: oswaldbook;
  font-size: 26px;
  text-align: center;
  padding: 30px 0;
}
.loginBox {
  background: #fff;
  width: 100%;
  max-width: 734px;
  padding: 20px;
  min-height: 200px;
  margin: auto;
  padding-bottom: 50px;
}
.returningCustomer {
  width: 50%;
  float: left;
  padding-right: 50px;
}
.retCustHd {
  position: relative;
  padding-left: 36px;
}
</style>  