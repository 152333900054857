<template>
  <div class="main mt-3" >
      <div class="header-block">
        <h1>My Orders</h1>
      </div>
      <div class="card">
        <div>
            <div class="flex">
                <left-sidebar />
                <div class="flex-auto flex text-gray-900">
                  <div class="card-container w-full">
                    <div class="table-responsive">
                       <table class="table">
                        <thead>
                          <tr>
                            <td scope="col">S.NO</td>
                            <td scope="col">ORDER ID</td>
                            <td scope="col">ORDER DATE</td>
                            <td scope="col">PRICE</td>
                            <td scope="col">STATUS</td>
                            <td scope="col"></td>
                          </tr>
                        </thead>
                        <tbody v-if="!orders">
                          <tr>
                            <td colspan="5">No Orders Made By You!</td>
                          </tr>
                        </tbody>
                        <tbody v-else-if="orders">
                          <tr v-for="(order, index) of orders">
                            <td scope="col">{{ index + 1 }}</td>
                            <td scope="col">{{ order.id }}</td>
                            <td scope="col">{{ order.created_at }}</td>
                            <td scope="col">${{ order.total_price }}</td>
                            <td scope="col">{{ this.status[order.status] }}</td>
                            <td scope="col"><router-link :to="`/user/order/${order.id}`">more info</router-link></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  </template>
      
  <script>
  import axios from 'axios';
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import LeftSidebar from '@/components/profile/LeftSidebar';
 
export default {
  name: 'OrderPage',
  components: {
    LeftSidebar: LeftSidebar
  },
  data() {
    return {      
      orders: null,
      status: ['Not set', 'Creating', 'Pending', 'Processing', 'Shipped', 'Canceled']
    }
  },
  methods: {
    getUserOrders() {
      let userEmail = this.$store.state.profileModule.userEmail;
      let form = new FormData;
      form.append('action', 'dm_get_orders_by_user');
      form.append('user_email', userEmail);
      axios({
          method: 'post',
          url: this.$wpAjaxUrl,
          data: form
      }).then(response => {
        this.orders = response.data.result;
      }).catch(error => {
          console.log(error);
      });
    }
  },
  async created () {
    if (this.$route.meta.noAuthRequired) {
      return;
    }
    let isValid = await this.$store.dispatch('checkLoginState');
    if (!isValid) {
      this.$router.push({
        name: 'login'
      });
    }
    },
  beforeRouteUpdate (to, from, next) {
  },
  mounted() {
    this.getUserOrders();
  }
}
</script>
      
  <style scoped>
  h1{
    margin-left: 10px;
    font-size: 27px;
    font-family: Oswald;
  }    
  .card {
    background: #ffffff;
    padding: 2rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    margin-bottom: 2rem;
    display: flex;
  }
  .loginHd {
    color: #272727;
    font-family: oswaldbook;
    font-size: 26px;
    text-align: center;
    padding: 30px 0;
  }
  .loginBox {
    background: #fff;
    width: 100%;
    max-width: 734px;
    padding: 20px;
    min-height: 200px;
    margin: auto;
    padding-bottom: 50px;
  }
  .returningCustomer {
    width: 50%;
    float: left;
    padding-right: 50px;
  }
  .retCustHd {
    position: relative;
    padding-left: 36px;
  }
  </style>  