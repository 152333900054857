<template>
    <div class="main mt-3" >
        <div class="header-block">
          <h1>Orders №{{$route.params.order}}</h1>
        </div>
        <div class="card">
          <div>
              <div class="flex">
                  <left-sidebar />
                  <div class="flex-auto flex text-gray-900">
                    <div class="card-container w-full">
                      <div class="table-responsive">
                         <table class="table">
                          <thead>
                            <tr>
                              <td scope="col"></td>
                              <td scope="col"></td>
                              <td scope="col"></td>
                            </tr>
                          </thead>
                          <tbody v-if="!orders">
                            <tr>
                              <td colspan="5">No Orders Made By You!</td>
                            </tr>
                          </tbody>
                          <tbody v-else-if="orders">
                            <tr v-for="(order, index) of orders" :key="index">
                              <td scope="col">
                                <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" :src="`${this.pathPluginImage}/thumb/${order.product_image_front}`" :alt="order.product_name" />
                                <img v-if="order.product_image_back" class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" :src="`${this.pathPluginImage}/thumb/${order.product_image_back}`" :alt="order.product_name" />
                              </td>
                              <td scope="col">{{order.product_name}}</td>                              
                              <td scope="col"><router-link :to="`/editor/${order.product_slug}/order-product-edit/${order.order_id}`">edit</router-link></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      </div>
    </template>
        
    <script>
    import axios from 'axios';
    import LeftSidebar from '@/components/profile/LeftSidebar';
   
  export default {
    name: 'OrderInfoPage',
    components: {
      LeftSidebar: LeftSidebar
    },
    data() {
      return {      
        orders: [],       
      }
    },
    methods: {
      getUserOrderInfo() {
        let userEmail = this.$store.state.profileModule.userEmail;
        let form = new FormData; 
        form.append('action', 'dm_get_order_by_id');
        form.append('customer_email', userEmail);
        form.append('order_id', this.$route.params.order);
        axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
        }).then(response => {
          this.orders = response.data.result;
        }).catch(error => {
            console.log(error);
        });
      }
    },
    async created () {
      if (this.$route.meta.noAuthRequired) {
        return;
      }
      let isValid = await this.$store.dispatch('checkLoginState');
      if (!isValid) {
        this.$router.push({
          name: 'login'
        });
      }
      },
    beforeRouteUpdate (to, from, next) {
    },
    mounted() {
      this.getUserOrderInfo();
    }
  }
  </script>
        
    <style scoped>
    h1{
      margin-left: 10px;
      font-size: 27px;
      font-family: Oswald;
    }    
    .card {
      background: #ffffff;
      padding: 2rem;
      box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
      border-radius: 4px;
      margin-bottom: 2rem;
      display: flex;
    }
    .loginHd {
      color: #272727;
      font-family: oswaldbook;
      font-size: 26px;
      text-align: center;
      padding: 30px 0;
    }
    .loginBox {
      background: #fff;
      width: 100%;
      max-width: 734px;
      padding: 20px;
      min-height: 200px;
      margin: auto;
      padding-bottom: 50px;
    }
    .returningCustomer {
      width: 50%;
      float: left;
      padding-right: 50px;
    }
    .retCustHd {
      position: relative;
      padding-left: 36px;
    }
    </style>  