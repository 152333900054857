<template>
	<div class="main" id="editor">
		<div class="header-block">		
        <h2>Page not found</h2>
		</div>
		<div class="card not-fount-page" >
      <h1>Error 404</h1>
      <router-link :to="'/'">
          <Button class="cart-link">Home page</Button>
      </router-link>
      <router-link :to="'/editor'">
          <Button class="cart-link">Create new Label</Button>
      </router-link>
    </div>
	</div>
</template>
<script>
export default {
    name: 'NotFoundPage',
    data() {
      return {
        
      }
    },
    methods: {
    
    }
  }
  </script>
  
  <style scoped>
   h1{
	margin-left: 10px;
	font-size: 27px;
	font-weight: bold;
  }
  #editor{
	display:block;
	margin-top: 21px;
  }
  .card{
    display: block !important;
  }
  .card>a{
    margin: 10px 30px;
  }
  </style>
  