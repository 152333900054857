import axios from 'axios';

const mutations = {
  cacheUser(state, { isLoggedIn, token, email, displayName, isGuest }) {
    state.isLoggedIn = isLoggedIn;
    state.userToken = token;
    state.userEmail = email;
    state.userDisplayName = displayName;
    state.isGuest = isGuest;
  },

  setAdmin(state, { isAdmin }) {
    state.isAdmin = isAdmin;
  },

  deleteUserCache(state) {
    state.isLoggedIn = false;
    state.userToken = '';
    state.userEmail = '';
    state.userDisplayName = '';
    state.isAdmin = false;
    state.isGuest = false;
  },
};

const actions = {
  async login({ commit }, payload) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/jwt-auth/v1/token`, {
      username: payload.username,
      password: payload.password,
    });
    const data = response.data;

    localStorage.setItem('isLoggedIn', true);
    localStorage.setItem('token', data.token);
    localStorage.setItem('displayName', data.user_display_name);
    localStorage.setItem('email', data.user_email);
    localStorage.setItem('isGuest', false);
  
    await commit('cacheUser', {
      isLoggedIn: true,
      token: data.token,
      email: data.user_email,
      displayName: data.user_display_name,
      isGuest: false,
    });

    if (data.user_email) {
      const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/is-admin`, {
        'user_email': data.user_email
      });

      await commit('setAdmin', {
        isAdmin: response.data.is_admin
      });
      localStorage.setItem('isAdmin', response.data.is_admin);
    }
    return true;
  },
  // async getPersonalInfo({ commit }, payload) {
  //   try {
  //     const token = localStorage.getItem('token');
  //     const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/personal-info`, 
  //       { user_email: payload.user_email }, 
  //       // { headers: { Authorization: `Bearer ${token}` } }
  //     );
  //     this.userData = response.data;
  //   } catch (error) {
  //     console.error('Error fetching user data', error);
  //   }
  // },
  async logout({ commit }) {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('displayName');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('isGuest');

    commit('deleteUserCache');
  },

  async checkLoginState({ commit }) {
    const token = localStorage.getItem('token');
    const isGuest = localStorage.getItem('isGuest') == true;
    
    if (!token && !isGuest) {
      await commit('deleteUserCache');
      return false;
    }

    try {
      if (token && !isGuest) {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/jwt-auth/v1/token/validate`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        await commit('cacheUser', {
          isLoggedIn: true,
          token,
          email: localStorage.getItem('email'),
          displayName: localStorage.getItem('displayName'),
          isGuest: false,
        });

        const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/is-admin`, {
          'user_email': localStorage.getItem('email')
        });

        await commit('setAdmin', {
          isAdmin: response.data.is_admin
        });
      } else {
        await commit('cacheUser', {
          isLoggedIn: false,
          token: '',
          email: localStorage.getItem('email'),
          displayName: 'Guest',
          isGuest: true,
        });
      }
      
      return true;
    } catch (error) {
      localStorage.setItem('token', '');
      return false;
    }
  },
  async register({ commit }, payload) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/register`, payload);
    return {
      status: response.data,
    };
  },
  async changePassword({ commit }, payload) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/changePassword`, payload);
    return {
      status: response.data
    };
  },
  async getPersonalInfo({ commit }, payload) {
    let headers = {'Authorization':  `Bearer ${localStorage.getItem('token')}`};
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/personal-info`, payload, {headers});
    return {
      status: response.data
    };
  },
  async updatePersonalInfo({ commit }, payload) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/update-personal-info`, payload);
    return {
      status: response.data
    };
  },
  async forgotPassword({ commit }, payload) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/forgot-password`, payload);
    return {
      status: response.data
    };
  },
  async resetPassword({ commit }, payload) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/reset-password`, payload);
    return {
      status: response.data
    };
  },
  async isAdmin({ commit }, payload) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/is-admin`, payload);

    await commit('setAdmin', {
      isAdmin: response.data.is_admin
    });
  },
  async checkUser({ commit }, payload) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/checkUser`, payload);
    const data = response.data;

    if (!data.registered) {
      localStorage.setItem('displayName', 'Guest');
      localStorage.setItem('email', payload.userEmail);
      localStorage.setItem('isGuest', true);
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('token');

      await commit('cacheUser', {
        isLoggedIn: false,
        token: '',
        email: payload.userEmail,
        displayName: 'Guest',
        isGuest: true
      });
      
      return true;
    }
    
    return {
      status: response.data.status,
    };
  }
};

const profileModule = {
  state: {
    isAdmin: localStorage.getItem('isAdmin') == true,
    isLoggedIn: localStorage.getItem('isLoggedIn') == true,
    userToken: localStorage.getItem('token') || '',
    userEmail: localStorage.getItem('email') || '',
    userDisplayName: localStorage.getItem('displayName') || '',
    isGuest: localStorage.getItem('isGuest') == true,
  },

  mutations,
  actions,
  namespace: 'profileModule'
};

export default profileModule;
