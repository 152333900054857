<template>
	<div class="main" id="editor">
		<div class="header-block">
			<h1>Categories</h1>
		</div>
		<div class="card p-0">
			<div class="content-page grid m-0" v-if="productCategories.length">
				<div class="col-6" v-for="category in productCategories" :key="category.id">
					<div class="text-center p-3 border-50 border-round-sm surface-100 font-bold">
						<router-link class="no-underline" :to="'/category/' + category.category_slug" exact>
							<span>{{category.category_name}}</span>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template> 
<script>
import axios from 'axios';
import {toRaw} from 'vue';
 export default {
   name: 'CategoriesPage',
   data() {
     return {
       products: [],
	   layout: 'grid',
	   productCategories: []
     }
   	},
   	methods: {
		getCategoriesList() {
            let form = new FormData;
            form.append('action', 'dm_get_product_categories');
            axios({
                method: 'post',
                url: this.$wpAjaxUrl,
                data: form
            }).then(response => {
                this.productCategories = response.data.result;
            }).catch(error => {
                console.log(error);
			});
		}
 	},
	mounted() {
		this.getCategoriesList();
	},
	
    created () {
      this.getCategoriesList();
    },
    beforeRouteUpdate(to, from, next) {
      this.getCategoriesList();
      next();
    }
 }
 </script>
 
 <style scoped>
   h1{
	margin-left: 10px;
	font-size: 27px; 
	font-family: Oswald;
  }
  #editor{
	display:block;
	margin-top: 21px;
  }
  .category_description{
	text-align: justify;
	padding: 20px;
  }

  .card {
    background: #ffffff;
    padding: 2rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    margin-bottom: 2rem;
	display: flex;
}
.p-dropdown {
    width: 14rem;
    font-weight: normal;
}
.product-name {
	font-size: 12px;
	font-weight: 700;
	cursor:pointer;
}
.product-description {
	margin: 0 0 1rem 0;
	font-size: 12px;
}
.product-category-icon {
	vertical-align: middle;
	margin-right: .5rem;
}
.product-category {
	font-weight: 600;
	vertical-align: middle;
}
.product-grid-item{
	margin: .5rem;
	border: 1px solid var(--surface-border);
}
.img-prod {
	margin: 0 15%;
	width: 200px;
	cursor: pointer;
}
.product-price {
	font-size: 14px;
	font-weight: 600;
	margin-bottom: .5rem;
	align-self: flex-end;
}
.product-list-action {
	display: flex;
	flex-direction: column;
}
.p-button {
	margin-bottom: .5rem;
}
.product-grid-item-top,
.product-grid-item-bottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.product-grid-item-content {
	text-align: center;
}
.product-price {
	font-size: 14px;
	font-weight: 600;
}
.other-variant-button{
	width: 100%;
	height: 40px;
}
.button-variant{
	height: 30px;
}
 </style>
 