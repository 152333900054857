<template>
  <section>
    <div v-if="loading" class="preloader">
        <ProgressSpinner strokeWidth="8" animationDuration=".7s"/>
    </div>
    <div class="main" id="editor" v-if="posts && posts.length">
      <div class="header-block">
        <h1>Blog</h1>
      </div>
      <div class="content-accordion">
      <div class="conten-page-top" v-html="contentPage"></div>    
          <Card v-for="post in posts" :key="post.ID">
              <template #title>{{ post.post_title }} </template>
              <template #content>
                  <p>
                      {{ post.post_excerpt }} 
                      <router-link :to="`/blog/${post.ID}`" exact>
                          <span>Read more ...</span>
                      </router-link>
                  </p>
                  <div class="publish"><i>Published:</i> {{ post.post_date }}</div>
              </template>
          </Card>
          <Paginator 
          :rows="rowsPerPage" 
          :totalRecords="totalRecords" 
          :first="(currentPage - 1) * rowsPerPage"
          @page="onPageChange"
          :pageLinkSize="3">
        </Paginator>
      </div>
    </div>
  </section>
</template>  
<script>
import axios from 'axios';
import Card from 'primevue/card';

export default {
  name: 'BlogPage',
  components:{
    Card
  },
  data() {
    return {
      titlePage: '',
      contentPage: '',
      posts: [],
      loading: false,
      currentPosts: [],
      totalRecords: 0,
      rowsPerPage: 10,
      currentPage: 1,
    }
  },
  methods: {
      getDataPage(page = 1) {      
        this.loading = true; 
        let form = new FormData;
        form.append('action', 'dm_get_page_by_slug_faq_blog');
        form.append('slug', 'blog');
        form.append('page', page);
        form.append('per_page', this.rowsPerPage);
        axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
        }).then(response => {
            this.loading = false;
            if (response.data && response.data.status == 'OK') {
              this.titlePage = response.data.pageTitle;
              this.contentPage = response.data.pageContent;
              this.posts = response.data.posts;
              this.totalRecords = response.data.totalPosts;
              this.currentPosts = this.posts;
              this.currentPage = page;
            } else {
                this.$router.push('/404');
            }
        }).catch(error => {
            console.log(error);
            this.loading = false;
        });
      },
      onPageChange(event) {
        const page = Math.ceil(event.first / this.rowsPerPage) + 1;
        this.getDataPage(page);
      }
  },
  mounted() {
    this.getDataPage(this.currentPage);
  },
  beforeRouteUpdate (to, from, next) {
    this.getDataPage();
    next();
  }
}
</script>  
<style scoped>
  h1{
  margin-top: 20px;
  font-family: Oswald;
  font-size: 26px;
}
#editor{
display:block;
margin-top: 21px;
}
.conten-page-top{
  text-align: center;
  margin-bottom: 20px;
}
.content-accordion{
  background-color: white;
  padding: 0 20px 20px 20px;
}
.heading-params{
  color: red;
}
.content-accordion{
    padding-top: 10px;
}
.p-card .p-card-body{
    margin-bottom: 10px !important;
}
.publish{

}
</style>
  