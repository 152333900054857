<template>
  <div class="headerCon">
        <div class="container top-head">
            <div class="logo">
                <a @click="$router.push('/')">
                  <img :src="`${this.pathUrl}/images/template/logo.png`" alt="www.noontimelabels.com" class="desktoplogo">
                </a>
            </div>
            <div class="rightSec flex flex-column md:flex-row gap-5">
              <div class="p-inputgroup flex-2">
                <Button v-if="!this.$store.state.profileModule.isLoggedIn && !this.isGuest"
                  class="is-logged-class"
                  type="button"
                  label=""
                  @click="openLoginPage()"
                  aria-haspopup="true"
                  aria-controls="overlay_menu">
                    <i class="pi pi-user" />
                    <span class="ml-1">Login/Signup</span> 
                </Button>  
                <Button v-if="this.$store.state.profileModule.isLoggedIn"
                  class="is-logged-class"
                  type="button"
                  label=""
                  @click="toggle"
                  aria-haspopup="true"
                  aria-controls="overlay_menu">
                    <i class="pi pi-user" />
                    <span class="ml-1">Welcome {{ this.$store.state.profileModule.userDisplayName }} <!--i class="pi pi-chevron-down" />--></span> 
                </Button>                  
                <Menu ref="menu" id="overlay_menu" :model="itemsProfile" :popup="true">
                  <template #start>
                  </template>
                  <template #end>
                    <button
                      class="w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround"
                      @click="logout">
                        <i class="pi pi-sign-out" />
                        <span class="ml-2">Log Out</span>
                    </button>
                  </template>
                </Menu>
                <Button v-if="!this.$store.state.profileModule.isLoggedIn && this.isGuest"
                  class="is-logged-class"
                  type="button"
                  label=""
                  @click="toggleG"
                  aria-haspopup="true"
                  aria-controls="overlay_menu_guest">
                    <i class="pi pi-user" />
                    <span class="ml-1">Welcome Guest <!--i class="pi pi-chevron-down" />--></span> 
                </Button>  
                <Menu ref="menuG" id="overlay_menu_guest" :popup="true">
                  <template #start>
                  </template>
                  <template #end>
                    <button
                      class="w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround"
                      @click="logout">
                        <i class="pi pi-sign-out" />
                        <span class="ml-2">Log Out</span>
                    </button>
                  </template>
                </Menu>
                <Toast />
                <template v-if="(this.$route.name == 'editor' && this.is_admin_edit && this.$store.state.order.isAdminAddEdit && !this.isGuest)
                || (this.$store.state.profileModule.isLoggedIn && this.$store.state.profileModule.isAdmin && this.$route.name == 'editor')">
                  <a   
                    class="shoppingCart"
                    @click="saveProductModal">
                    <i class="pi pi-save" /> Save product
                  </a>
                  </template>
                  <template  v-else>
                    <div class="shoppingCart" style="visibility: hidden;"></div>
                  <!-- <a
                    class="shoppingCart"
                    @click="$router.push('/editor')">
                    <i class="pi pi-save" /> Add product
                  </a> -->
                </template>
                <a
                  class="shoppingCart"
                  @click="$router.push('/shoping-cart')">
                  <img :src="`${this.pathUrl}/images/template/mycart_icon.png`" class="mycart" alt=""> Shopping Cart
                </a>
            </div>
            <div class="p-inputgroup flex-2">
                <InputText class="searchBar" v-model="queryString" placeholder="Search By Label Name" />
                <Button icon="pi pi-search" class="searchBar-button" @click="findLabel" />
            </div>
            <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="navigation">
          <Menubar :model="items" @mouseover="setActiveMenu($event)" @mouseleave="removeActiveMenu()"></Menubar>
        </div>
    </div>
</template>  
<script>
import Menubar from 'primevue/menubar';
import Menu from 'primevue/menu'
import axios from 'axios';
import { isProxy, toRaw } from 'vue';
export default {
  name: 'TopBar',
  components: {
    Menubar: Menubar,
    Menu: Menu
  },
  data() {
    return {
      is_admin_edit: false,
      activeClass: null,
      itemsProfile: [
          { 
            items: [
                {
                  label: 'My Profile',
                  class: 'link-menu',
                  icon: 'pi pi-fw pi-user',
                  to: '/user'
                },
                {
                  label: 'My Orders',
                  class: 'link-menu',
                  icon: 'pi pi-list',
                  to: '/user/orders'
                },
                {
                  label: 'My Labels',
                  class: 'link-menu',
                  icon: 'pi pi-heart',
                  to: '/user/mylabels'
                }
            ]
          }
      ],
      items: [],
      singlePage: ['faq/', 'contact/'],
      queryString: '',
      isGuest: false
    }
  },
  async created() {
    await this.$store.dispatch('checkLoginState');
  },
  methods: {
    openLoginPage() {
      if (this.$route.name == 'editor') {
          this.emitter.emit('popUpLogin');
      } else {
          this.$router.push('/login');
      }
    },
    async logout() {
      this.isGuest = false;
      await this.$store.dispatch('logout');
      this.$router.push({ name: 'login' });
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    toggleG(event) {
      this.$refs.menuG.toggle(event);
    },
    save() {
      this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Data Saved', life: 3000 });
    },
    findLabel() {
      if (this.queryString && this.queryString.length > 2) {
        this.$router.push({path: '/shop/all-custom-labels', query: {s: this.queryString} });
        this.queryString = '';
      }
    },
    getWPMenu() {
      let form = new FormData;
      form.append('action', 'dm_get_main_menu');
      axios({
          method: 'post',
          url: this.$wpAjaxUrl,
          data: form
      }).then(response => {
        let self = this;
        if (response.data && response.data.mainMenu) {
          let tempItems = [];
          let baseUrl = process.env.NODE_ENV=='development' ? process.env.VUE_APP_BASE_URL : `${window.location.protocol}//${window.location.host}`;
          let menu = response.data.mainMenu;
          for (let i=0; i<menu.length; i++) {
            if (menu[i].menu_item_parent == "0") {
              if (menu[i].attr_title == 'not_link') {
                tempItems.push(
                  {
                    label: menu[i].title,
                    id: menu[i].ID,
                    icon: 'pi pi pi-angle-down',
                    to: menu[i].url.replace(baseUrl, '') == '' ? menu[i].url.replace(baseUrl, '/') : menu[i].url.replace(baseUrl, ''),
                    items: []
                  }
                );
              } else {
                tempItems.push(
                  {
                    label: menu[i].url.replace(baseUrl, '') == '' ? 'Home' : menu[i].title,
                    id: menu[i].ID,
                    to: menu[i].url.replace(baseUrl, '') == '' ? menu[i].url.replace(baseUrl, '/') : menu[i].url.replace(baseUrl, ''),
                    items: []
                  }
                );
              }
            }
          }
          self.items = isProxy(tempItems) ? toRaw(tempItems) : tempItems;
          for (let i=0; i<tempItems.length; i++) {
             menu.map(function(item){
              if (self.items[i].id == item.menu_item_parent) {
               return self.items[i].items.push({
                  label: item.title,
                  class: 'list-menu second-menu',
                  to: item.url.replace(baseUrl, '')
                });
              }
            });
          }
        }
      }).catch(error => {
          console.log(error);
      });
    },
    saveProductModal() {
      if ((this.$store.state.profileModule.isLoggedIn && this.$store.state.profileModule.isAdmin) || this.$store.state.order.isAdminAddEdit || this.is_admin_edit) {
        this.emitter.emit('showSaveProductPopUp');
      } else {
        this.emitter.emit('popUpLogin');
      }			
		},
    checkIsAdmin(param) {
      this.is_admin_edit = param.isAdmin;
    },
    setActiveMenu(event) {
      if (!event.target) {
        return;
      }
      //TODO 
      let isActiveMenu = false;
      let targetTag = event.target;
      let activeID = null;
      let focusedTag = null;

      if (targetTag && targetTag.nodeName == 'LI') {
        focusedTag = targetTag;
      }
      if (targetTag && targetTag.nodeName == 'SPAN') {
        focusedTag = targetTag.parentNode.parentNode.parentNode;
      }
      if (targetTag && targetTag.nodeName == 'A') {
          focusedTag = targetTag.parentNode.parentNode;
      }
      if (targetTag && targetTag.nodeName == 'DIV') {
        focusedTag = targetTag.parentNode;
      }
      if (focusedTag && focusedTag.nodeName == 'LI' && (!focusedTag.classList.contains('p-menuitem-active') &&
         focusedTag.parentNode.nodeName == 'UL' && focusedTag.parentNode.classList.contains('p-menubar-root-list'))) {
          let activeBlock = document.querySelectorAll('.p-menubar-root-list>li');
          if (activeBlock && activeBlock.length && !isActiveMenu) {
            for (let i=0; i<activeBlock.length; i++) {
              if (focusedTag.nodeName == 'LI' && 
                focusedTag.parentNode.classList.contains('p-menubar-root-list') && focusedTag.id != activeBlock[i].id) {
                activeBlock[i].classList.remove('p-menuitem-active');
                activeBlock[i].classList.remove('p-highlight');
                activeBlock[i].classList.remove('p-focus');
                activeBlock[i].removeAttribute('aria-expanded');
              }
            }
          }

          isActiveMenu = true;
          activeID = focusedTag.id;
          focusedTag.parentNode.setAttribute('aria-activedescendant', activeID);
          focusedTag.classList.add('p-menuitem-active');
          focusedTag.classList.add('p-highlight');
          focusedTag.classList.add('p-focus');
          focusedTag.setAttribute('aria-expanded', true);
      }
    },
    removeActiveMenu() {
      document.querySelector('.p-menubar-root-list').removeAttribute('aria-activedescendant');
      let activeBlock = document.querySelectorAll('.p-menubar-root-list>li');
      if (activeBlock && activeBlock.length) {
        for (let i=0; i<activeBlock.length; i++) {
          activeBlock[i].classList.remove('p-menuitem-active');
          activeBlock[i].classList.remove('p-highlight');
          activeBlock[i].classList.remove('p-focus');
          activeBlock[i].removeAttribute('aria-expanded');
        }
      }
    },
    checkIsGuest() {
      let isGuest = localStorage.getItem('isGuest');
      this.isGuest = isGuest;
    }
  },
  mounted() {
    this.checkIsGuest();
    this.getWPMenu();
    this.emitter.on('checkIsAdmin', (data) => this.checkIsAdmin(data));
  }
}
</script>
  
<style>
.headerCon {
    height: 100px;
    background: url('../../../public/images/template/header-back.jpg') repeat-x;
}
.logo {
    float: left;
    cursor: pointer;
}
.rightSec {
    float: right;
    padding-top: 20px;
}
.myaccount {
    position: relative;
}
.myaccount {
    /* background: url('../../public/images/template/myaccount_icon.png') no-repeat left center; */
    padding-left: 18px;
    font-size: 14px;
    vertical-align: middle;
    color: #fff;
    transition: none 0s ease 0s;
    -webkit-transition: none 0s ease 0s;
    cursor: pointer;
}
.shoppingCart {
    color: #fff;
    background: url('../../../public/images/template/myaccountBtn-back.png') repeat-x;
    width: 140px;
    height: 25px;
    display: inline-block;
    font-size: 14px;
    vertical-align: middle;
    margin: 0 5px;
    border: 1px solid #dc6b00;
    padding: 2px 0 2px 10px;
    border-radius: 10px;
    cursor: pointer;
}
.navigation{
   max-width: 1000px;
  width: 100%;
  margin: auto;
}
.p-menuitem{
  background: rgba(0, 0, 0, 0) url('../../../public/images/template/menuback.png') no-repeat scroll 0 0;
}
span.p-menuitem-text{
  text-transform: uppercase;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
  width: 120px;
  height: 40px;
  font-weight: 400;
  font-size: 13px !important;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    width: 120px;
}
.p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    font-size: 13px !important;
    color: black
}
.p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    font-size: 13px;
    color: black
}
.p-focus{
  color: black !important;
}
.p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link {
    padding: 0.75rem 5px;
    text-align: center;
}
.top-block>a{
  float: right;
}
.page {
    width: 100%;
    height: 124px;
    background: #111;
}
.main-menu{
  width: 952px;
  margin: 0 auto;
}
.top-block{
  height: 76px;
  width: 952px;
  margin: 0 auto;
  text-align: right;
}
.list-menu{
  color: #b6b28e;;
}
.p-menubar {
    padding: 0.5rem;
    background:none !important;
    color: #b6b28e;
    border: none !important; 
    border-radius: none; 
    margin: 0 auto;
}
.p-menuitem-content{
  background-color: none;
}
.p-menubar .p-menuitem.p-highlight > .p-menuitem-content {
    color: #000 !important;
    border: none;
}
.p-menuitem .p-menuitem-active .p-highlight{
  color: #000 !important;
}
.p-menuitem-content>a>span.p-menuitem-text{
  color: #b6b28e !important;
}
.p-menubar .p-submenu-list {
    z-index: 2;   
}
li.p-menuitem>ul.p-submenu-list{
  background-color: none !important;
}
.p-menuitem-content>a>span.p-menuitem-text{
  color: white !important;
}
.p-menubar .p-submenu-list{
  padding: 0;
  width: 175px !important;
}
.p-menubar .p-menuitem {
    border: 1px solid #737b82;
}
.navigation{
  display: flex;
  align-items: center !important;
}
.p-menuitem .list-menu{
  width: 175px !important;
  font-size: 13px !important;
}
.p-inputgroup{
  height: 25px;
}
.searchBar-button{
  height: 25px;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border: none;
  background-color: #ed640d !important;
  margin-left: -2px !important;
}
.searchBar {   
    width: 160px;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    color: #000;
    font-size: 11px;
    padding: 5px 10px;
    border: 0;
    font-family: 'open_sansregular';
    font-weight: 600;
    font-style: italic;
}
input,input:focus,button,button:focus{
  outline: none;
  box-shadow:none !important;
  border:none !important;
}
.pi{
  line-height: 0 !important;
}
.link-menu>.p-menuitem-content>a>span{
  color: black !important;
}
.link-menu>.p-menuitem-content{
  background-color: #fff !important;
}
#overlay_menu_list>li:first-child{
  display:none !important;
}
.is-logged-class{
  width: 170px !important;
  height: 35px;
  line-height: 0.8;
  background-color: transparent !important;
}
.second-menu>div.p-menuitem-content>a.p-menuitem-link{
  text-align: left !important;
}
</style>
  