<template>
	<div class="main" id="editor" style="width: 985px">
    <div v-if="loading" class="preloader">
      <ProgressSpinner strokeWidth="8" animationDuration=".7s"/>
    </div>
		<div class="header-block">
			<h1 v-html="titlePage"></h1>
		</div>
    <div class="content-page" v-if="loading">
      <Skeleton width="100%" height="400px"></Skeleton>
    </div>
		<div class="content-page" v-if="!loading" v-html="contentPage"></div>
    <div class="content-page" v-if="isSearch">
      <h3 v-if="products.length">Found {{ products.length }} results for '{{ searchWord }}'</h3>
      <h3 v-else>Nothing found  for '{{ searchWord }}'</h3>
    </div>
    <DataView :value="products" :layout="layout" :paginator="false" :rows="9">
				<template #grid="slotProps">
					<div class="col-12 col-md-4" v-if="slotProps.data.image_front">
						<div class="product-grid-item">
							<img class="img-prod"
								:src="`${this.pathPluginImage}/products/${slotProps.data.image_front}`"
								:alt="slotProps.data.name"
								@click="$router.push(slotProps.data.count_product > 0 ? '/product-variants/' + this.$route.params.id + '/' + slotProps.data.product_slug : '/editor/' + slotProps.data.product_slug)"
								/>
							<div class="product-grid-item-content">
								<div class="product-name">{{slotProps.data.name}}</div>
								<div class="product-name"><b>Size:</b> 
									{{JSON.parse(slotProps.data.backgrounds_shape).front.width}}"&times;
									{{JSON.parse(slotProps.data.backgrounds_shape).front.height}}"
								</div>
							</div>
						</div>
					</div>
				</template>
			</DataView>
      <div class="content-page grid" v-if="childPosts.length">
        <div class="col-6" v-for="post in childPosts" :key="post.id">
            <div class="text-center p-3 border-50 border-round-sm surface-100 font-bold">
              <router-link class="no-underline" :to="post.post_name" exact>
                <span>{{post.post_title}}</span>
              </router-link>
            </div>
        </div>
      </div>
      <div v-if="products.length < totalProducts" class="text-center my-3">
        <button class="p-button p-component" :disabled="isLoadingMore" @click="loadMoreProducts">
          <span v-if="!isLoadingMore">Load More</span>
          <span v-else>Loading...</span>
        </button>
      </div>
  </div>
</template>
  
  <script>
  import axios from 'axios';
  export default {
    name: 'ShopPage',
    data() {
      return {
        titlePage: '',
        contentPage: '',
        products: [],
        layout: 'grid',
        isSearch: false,
        searchWord: '',
        loading: false,
        childPosts: [],
        page: 1,
        perPage: 12,
        totalProducts: 0,
        isLoadingMore: false,
      }
    },
    methods: {
        getDataPage(slug) {
          this.loading = true;
          let form = new FormData;
          form.append('action', 'dm_get_page_by_slug');
          form.append('slug', slug && slug.id != '' ? 'shop/' + slug : 'shop/');
          axios({
              method: 'post',
              url: this.$wpAjaxUrl,
              data: form
          }).then(response => {
            this.loading = false;
            if (response.data && response.data.status == 'OK') {
              this.products = [];
              this.isSearch = false;
              this.titlePage = response.data.pageTitle;
              this.contentPage = response.data.pageContent;
              if (slug) {
                this.getProductsByName();
              } else {
                this.childPosts = response.data.child_posts;
                this.childPosts.unshift({//TODO
                  post_name: '/category/wine-labels',
                  post_title: 'Wine Labels'
                });
                this.childPosts.unshift({//TODO
                  post_name: '/category/beer-labels',
                  post_title: 'Beer Labels'
                });
              }             
            } else {
                this.$router.push('/404');
            }
          }).catch(error => {
              console.log(error);
          });
        },
        getProductsByName(loadMore = false) {
          if (this.$route.params.id && this.$route.params.id == 'all-custom-labels') {
            this.loading = !loadMore;
            this.isLoadingMore = loadMore;
            let form = new FormData;
            form.append('action', 'dm_get_products_by_name');
            if (this.$route.query && this.$route.query.s && this.$route.query.s.length > 2) {
              this.isSearch = true;
              this.searchWord = this.$route.query.s;
              form.append('name', this.$route.query.s);
            }
            form.append('page', this.page);
            form.append('perPage', this.perPage);
            axios({
              method: 'POST',
              url:this.$wpAjaxUrl,
              data: form
            }).then(response => {
              this.loading = false;
              this.isLoadingMore = false;
              if (loadMore) {
                this.products = [...this.products, ...response.data.products];
              } else {
                this.products = response.data.products;
              }
 
              this.totalProducts = response.data.totalProducts;
            }).catch(error => {
              console.log(error);
            });
          }
        },
        loadMoreProducts() {
              this.page++;
              this.getProductsByName(true);
        },
    },
    created () {
      this.getDataPage(this.$route.params.id);
    },
    beforeRouteUpdate(to, from, next) {
      this.getDataPage(to.params.id || to.params);
      next();
    }
  }
  </script>
  
  <style scoped>
   h1{
	margin-left: 10px;
	font-size: 27px;
  font-family: Oswald;
  }
  #editor{
	display:block;
	margin-top: 21px;
  }
  .content-page{
    background-color: white;
    height: fit-content;
   
  }
  .card {
    background: #ffffff;
    padding: 2rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    margin-bottom: 2rem;
	display: flex;
}
.p-dropdown {
    width: 14rem;
    font-weight: normal;
}
.product-name {
	font-size: 12px;
	font-weight: 700;
	cursor:pointer;
}
.product-description {
	margin: 0 0 1rem 0;
	font-size: 12px;
}
.product-category-icon {
	vertical-align: middle;
	margin-right: .5rem;
}
.product-category {
	font-weight: 600;
	vertical-align: middle;
}
.product-grid-item{
	margin: .5rem;
	border: 1px solid var(--surface-border);
}
.img-prod {
	margin: 0 15%;
	width: 200px;
	cursor: pointer;
}
.product-price {
	font-size: 14px;
	font-weight: 600;
	margin-bottom: .5rem;
	align-self: flex-end;
}
.product-list-action {
	display: flex;
	flex-direction: column;
}
.p-button {
	margin-bottom: .5rem;
}
.product-grid-item-top,
.product-grid-item-bottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.product-grid-item-content {
	text-align: center;
}
.product-price {
	font-size: 14px;
	font-weight: 600;
}
.other-variant-button{
	width: 100%;
	height: 40px;
}
.button-variant{
	height: 30px;
}
  </style>
  